import { formatString } from "./formatString";
import { answerChoiceLegendDomain } from "./legendMapping/answerChoiceLegend";
import { Categories, getModifedCategories, getNameFromVariable } from "./legendMapping/categoryLegend";

export const INSUFFICIENT_N_DISPLAY = "n < 5"

export const makeCategoryNDict = (selectedFilter: string, data: any, selector: string, categoryString?: string) => {
  let categories: Categories;
  if (categoryString) {
    categories = getModifedCategories(categoryString);
  } else {
    categories = getModifedCategories();
  }

  const currentDescription = categories[selectedFilter as keyof Categories];
  const filtered = data.filter((item: any) => item.type === "Your Institution");

  const categoryNdict: any = {};
  for (const description of currentDescription) {
    let currentData = filtered.find((item: any) => item[selector] === description);
    if (currentData) {
      categoryNdict[description] = currentData.total;
    } else {
      categoryNdict[description] = 0;
    }
  }

  return categoryNdict;
}

export const formatCurrencyString = (n: number) => {
  if (!n) return "";
  return `$${Intl.NumberFormat().format(Math.round(n))}`;
}

export const roundNumber = (num: number, twoDecimalPlaces?: boolean, tableDisplay?: boolean) => {

  let result;
  if (num === undefined) {
    return INSUFFICIENT_N_DISPLAY;
  }

  // round standard deviation and other named values to 2 decimal places
  if (twoDecimalPlaces) {
    result = Math.round(num * 100) / 100;
  }
  // round other values to 1 decimal place
  result = Math.round(num * 10) / 10;

  if (tableDisplay) {
    return result.toLocaleString();
  }
  return result;

}


export const determineNdisplay = (
  n: number | undefined,
  total?: boolean,
  tableDisplay?: boolean,
  redactTotal: boolean = true
) => {
  if (n === undefined) return INSUFFICIENT_N_DISPLAY;

  if (total && n < 5 && redactTotal) return INSUFFICIENT_N_DISPLAY;
  if (tableDisplay || !redactTotal) {
    return n.toLocaleString();
  }

  return n;
}

export const convertToPercentString = (
  n: number,
  total: number,
  noRedaction?: boolean,
  tableDisplay?: boolean
) => {

  if (noRedaction) {
    return String(Math.round((n / total) * 100 * 10) / 10) + "%";
  }

  // data values from api are undefined when n < 5
  if (n === undefined || !total) return INSUFFICIENT_N_DISPLAY;

  if (total < 5) {
    return INSUFFICIENT_N_DISPLAY;
  }

  // special case for appendix response rates
  if (tableDisplay && total && n < 5) return INSUFFICIENT_N_DISPLAY;

  // round to 1 decimal place
  return String(Math.round((n / total) * 100 * 10) / 10) + "%";
}

export const convertToPercent = (n: number, total: number) => {
  if (n === 0) return 0
  return Math.round(n / total * 100);
}

export const convertDecimalToPercent = (n: number) => {
  return (Math.round(n * 10000) / 100) + "%";
}

export const convertToDecimal = (n: number, total: number) => {
  if (n === 0 || total === 0) return 0
  return Math.round(n / total * 1000) / 1000;
}

export const formatLabels = (label: string) => {
  let result = "";
  let split = label.split("_");
  split.forEach((word, index) => {
    if (word === "institution") {
      result += "Your Institution";
    } else if (word === "stem") {
      result += "STEM"
    } else {
      result += word[0].toUpperCase() + word.substring(1);
    }
    if (index !== split.length - 1) {
      result += " ";
    }
  });

  return result;
}

export const getLegendDomain = (section: string, variableName?: boolean, total?: boolean) => {
  let domain = [];

  if (variableName) {
    for (const item of Object.keys(answerChoiceLegendDomain[section])) {
      domain.push(item);
    }
    if (total) {
      domain.push("total");
    }
  } else {
    for (const item of Object.values(answerChoiceLegendDomain[section])) {
      domain.push(item);
    }
  }

  return domain;
}

export const mapAnswerChoiceLegendDomain = (section: string, answerChoice: string) => {
  let result = answerChoiceLegendDomain[section][answerChoice];
  if (!result) return formatString(answerChoice);

  return result;
}

export const removeCommas = (str: string) => {
  return str.replace(/,/g, '');
}

export const createHeaderRowWithCommas = (
  arr: string[],
  n: number,
  selectedFilter?: string,
) => {
  const commaString = ",".repeat(n - 1);

  if (selectedFilter) {
      return arr.map(item => `${getNameFromVariable(selectedFilter, item)}${commaString}`)
  }
  return arr.map(item => `${formatLabels(item)}${commaString}`)
}