import { FactorsResponse, NestedResponseData, TripleNestedResponseData } from "../../models/responseData";
import { determineNdisplay, formatLabels, convertToPercentString, roundNumber, INSUFFICIENT_N_DISPLAY, getLegendDomain, removeCommas, mapAnswerChoiceLegendDomain, createHeaderRowWithCommas, convertDecimalToPercent } from "../utils";
import { Categories, getNameFromVariable, getModifedCategoriesVarNames } from "../legendMapping/categoryLegend";

const N_PERCENT_HEADERS = ["n", "%"];
const MEAN_STD_HEADERS = ["mean", "stdev"];
const AVG_RANK_NUM_SELECTED_TOTAL_HEADERS = ["Average Rank", "Number Selected", "Total"];
const INSTITUTION_COHORT_HEADERS = ["institution", "cohort"];
const REASON_TO_STAY_LEAVE_HEADERS = ["stay", "leave"];

let modifiedCategories = getModifedCategoriesVarNames(true);

const convertApiResponseToCSVPopulationData = (
    apiResponse: NestedResponseData | null,
    selectedFilter: string,
    totalRowOnly?: boolean,
    section?: string,
    answerChoice?: boolean
): string => {
    if (!apiResponse) return "";

    let csvContent = "";

    // Write the header row
    const types = Object.keys(apiResponse);

    let firstHeader = "";
    const thirdHeaders = N_PERCENT_HEADERS;

    let categories = Object.keys(apiResponse.cohort).filter((item) => item !== "total");


    let columnComparisonList: any;
    let rowComparisonList: any;

    if (answerChoice && section) {
        rowComparisonList = getLegendDomain(section, true, true);
        columnComparisonList = modifiedCategories[selectedFilter as keyof Categories];
    } else {
        rowComparisonList = modifiedCategories[selectedFilter as keyof Categories];
        columnComparisonList = modifiedCategories["exit_status"];
    }

    categories = categories.sort((a: any, b: any) =>
        columnComparisonList.indexOf(a) > columnComparisonList.indexOf(b) ? 1 : -1
    );

    for (const type of types) {
        firstHeader += `${formatLabels(type)},` + ",".repeat((categories.length) * (thirdHeaders.length) - 1);
    }

    csvContent += `,${firstHeader.slice(0, -1)}\n`;

    // Write the second row with category dynamically
    csvContent += categories.map(category => `,${removeCommas(getNameFromVariable(selectedFilter, category))},`).join("") + ",";
    csvContent += categories.map(category => `,${removeCommas(getNameFromVariable(selectedFilter, category))},`).join("").slice(1) + "\n"; // Remove leading comma

    // add the n and % headers
    categories.forEach((category) => csvContent += ",n,%,n,%")
    csvContent = csvContent + "\n";

    // Get the unique subcategories from the apiResponse
    let subcategories;
    if (apiResponse.cohort.total) {
        subcategories = Object.keys(apiResponse.cohort.total);
    } else {
        let temp = (Object.keys(apiResponse.cohort)[0]);
        subcategories = Object.keys(apiResponse.cohort[temp]);
    }

    subcategories = subcategories.sort((a: any, b: any) =>
        rowComparisonList.indexOf(a) > rowComparisonList.indexOf(b) ? 1 : -1
    );

    let dataContent = "";
    let totalRow = "";

    // Iterate over each subcategory
    for (const subcategory of subcategories) {
        // Add the subcategory to the CSV content
        let currentHeader = `${getNameFromVariable(selectedFilter, subcategory)}`;

        if (answerChoice && section) {
            currentHeader = mapAnswerChoiceLegendDomain(section, subcategory);
        }

        currentHeader = removeCommas(currentHeader);

        // escape excel date format for number range headers
        if (currentHeader.includes("-")) {
            dataContent += ` ${currentHeader},`;
        } else {
            dataContent += `${currentHeader},`;
        }


        // Iterate over each category
        for (const type of types) {
            let hasCategory = false;
            for (const category of categories) {
                if (category !== "total" && apiResponse[type][category]) {
                    hasCategory = true;
                    let subcategoryTotal = apiResponse[type][category].total;

                    if (selectedFilter === "exit_status" && totalRowOnly) {
                        subcategoryTotal = apiResponse[type].total.total
                    }
                    // Add the data for each category
                    let value = determineNdisplay(apiResponse[type][category][subcategory]);
                    let percentage = convertToPercentString(apiResponse[type][category][subcategory], subcategoryTotal)

                    if (subcategory === "total") {
                        value = determineNdisplay(apiResponse[type][category][subcategory], true);
                        totalRow += `${value},${percentage},`;
                    }

                    dataContent += `${value},${percentage},`;
                }

                if (!hasCategory) {
                    dataContent += `${INSUFFICIENT_N_DISPLAY},${INSUFFICIENT_N_DISPLAY},`;
                }

            }
        }
        // Move to the next line for the next subcategory
        dataContent = dataContent.slice(0, -1) + "\n";
    }

    // for section 1.1
    if (selectedFilter === "exit_status" && totalRowOnly) {
        csvContent += `Total,${totalRow}`;
    } else {
        csvContent += dataContent;
    }

    return csvContent;
}

const convertApiResponseToCSVPopulationDataTripledNested = (
    apiResponse: TripleNestedResponseData | null,
    selectedFilter: string,
    section: string
): string => {
    if (!apiResponse) return "";

    let csvContent = "";

    // Write the header row
    const types = Object.keys(apiResponse);
    let categories = Object.keys(apiResponse.cohort).filter((item) => item !== "total");
    const columnComparisonList = modifiedCategories[selectedFilter as keyof Categories];

    categories = categories.sort((a: any, b: any) =>
        columnComparisonList.indexOf(a) > columnComparisonList.indexOf(b) ? 1 : -1
    );


    let firstHeader = "";
    const thirdHeaders = N_PERCENT_HEADERS;


    for (const type of types) {
        firstHeader += `${formatLabels(type)},` + ",".repeat((categories.length) * (thirdHeaders.length) - 1);
    }

    csvContent += `,${firstHeader.slice(0, -1)}\n`;

    // Write the second row with category dynamically
    csvContent += categories.map(category => `,${removeCommas(getNameFromVariable(selectedFilter, category))},`).join("") + ",";
    csvContent += categories.map(category => `,${removeCommas(getNameFromVariable(selectedFilter, category))},`).join("").slice(1) + "\n"; // Remove leading comma

    // add the n and % headers
    categories.forEach((category) => csvContent += ",n,%,n,%")
    csvContent = csvContent + "\n";



    // Get the unique categories from the apiResponse
    let answerChoices = Object.keys(apiResponse.cohort.total);
    const comparisonList = getLegendDomain(section, true, true);

    answerChoices = answerChoices.sort((a: any, b: any) =>
        comparisonList.indexOf(a) > comparisonList.indexOf(b) ? 1 : -1
    );

    for (const answerChoice of answerChoices) {
        csvContent += `${formatLabels(answerChoice)},`;

        for (const type of Object.keys(apiResponse)) {
            for (const description of categories) {
                if (description !== "total") {
                    let descriptionTotal = 0;
                    let value;
                    let percentage;

                    descriptionTotal = apiResponse[type][description].total.total as unknown as number;

                    if (answerChoice !== "total" && apiResponse[type][description][answerChoice]) {
                        value = determineNdisplay(apiResponse[type][description][answerChoice].total) || 0;
                        percentage = convertToPercentString(apiResponse[type][description][answerChoice].total, descriptionTotal);
                        csvContent += `${value},${percentage},`;
                    } else if (answerChoice === "total") {
                        value = determineNdisplay(descriptionTotal);
                        percentage = convertToPercentString(descriptionTotal, descriptionTotal);
                        csvContent += `${value},${percentage},`;
                    } else if (!apiResponse[type][description][answerChoice]) {
                        csvContent += `${INSUFFICIENT_N_DISPLAY},${INSUFFICIENT_N_DISPLAY},`;
                    }
                }
            }
        }
        csvContent = csvContent.slice(0, -1) + "\n";
    }
    return csvContent;
}

// 7.1
const convertApiResponseToCSVPopulationDataTripledNestedSection7 = (
    apiResponse: TripleNestedResponseData | null,
    selectedFilter: string,
    section: string
): string => {
    if (!apiResponse) return "";

    let csvContent = "";

    // Write the header row
    const types = Object.keys(apiResponse.total);
    const workTitles = Object.keys(apiResponse).filter((item) => item !== "total");

    const categories = Object.keys(apiResponse.total.cohort).filter((item) => item !== "total");

    let rowComparisonList = modifiedCategories[selectedFilter as keyof Categories];

    categories.sort((a: any, b: any) =>
        rowComparisonList.indexOf(a) > rowComparisonList.indexOf(b) ? 1 : -1
    );

    let firstHeader = "";
    const fourthHeaders = N_PERCENT_HEADERS;


    for (const type of types) {
        firstHeader += `${formatLabels(type)},` + ",".repeat((workTitles.length) * (categories.length) * (fourthHeaders.length) - 1);
    }

    csvContent += `,${firstHeader.slice(0, -1)}\n`;

    // // Write the second row with title dynamically
    let secondHeaderRow = ",";
    secondHeaderRow += createHeaderRowWithCommas(workTitles, (categories.length * N_PERCENT_HEADERS.length));
    csvContent += secondHeaderRow.repeat(types.length) + "\n";



    // Write the third row with category dynamically
    let thirdHeaderRow = ",";
    thirdHeaderRow += createHeaderRowWithCommas(categories, N_PERCENT_HEADERS.length);

    csvContent += thirdHeaderRow.repeat(types.length * workTitles.length) + "\n";


    // add the n and % headers
    workTitles.forEach((category) => csvContent += `,${N_PERCENT_HEADERS.join(",")}`.repeat(types.length * categories.length))
    csvContent = csvContent + "\n";

    // Get the unique answer choices from the apiResponse
    let answerChoices = Object.keys(apiResponse[workTitles[0]].cohort.total).filter((item) => item !== "total");

    let answerChoiceList = getLegendDomain(section, true, true);
    answerChoices = answerChoices.sort((a: any, b: any) =>
        answerChoiceList.indexOf(a) > answerChoiceList.indexOf(b) ? 1 : -1
    );

    for (const answerChoice of answerChoices) {
        csvContent += `${formatLabels(answerChoice)},`;

        for (const type of types) {
            for (const workTitle of workTitles) {

                if (workTitle !== "total" && answerChoice !== "total") {
                    let categoryTotal = 0;
                    let value;
                    let percentage;
                    for (const category of categories) {
                        value = determineNdisplay(apiResponse[workTitle][type][category][answerChoice]);
                        categoryTotal = apiResponse[workTitle][type][category].total as unknown as number;
                        percentage = convertToPercentString(apiResponse[workTitle][type][category][answerChoice], categoryTotal)

                        csvContent += `${value},${percentage},`;
                    }
                }
            }
        }
        csvContent = csvContent.slice(0, -1) + "\n";
    }

    return csvContent;
}


const convertApiResponseToCSVStatistics = (
    apiResponse: NestedResponseData | null,
    selectedFilter: string
): string => {
    if (!apiResponse) return "";

    let csvContent = "";

    // Write the header row
    const types = Object.keys(apiResponse);


    let categories = Object.keys(apiResponse.cohort)
        .filter((item) => item !== "total" && item !== "preemptive_retention");

    let columnComparisonList: any;
    columnComparisonList = modifiedCategories[selectedFilter as keyof Categories];

    categories = categories.sort((a: any, b: any) =>
        columnComparisonList.indexOf(a) > columnComparisonList.indexOf(b) ? 1 : -1
    );

    let firstHeader = "";
    for (const type of types) {
        firstHeader += `${formatLabels(type)},` + ",".repeat(categories.length - 1);
    }

    csvContent += `,${firstHeader.slice(0, -1)}\n`;

    // Write the second row with category dynamically
    csvContent += categories.map(category => `,${removeCommas(getNameFromVariable(selectedFilter, category))}`).join("") + ",";
    csvContent += categories.map(category => `,${removeCommas(getNameFromVariable(selectedFilter, category))}`).join("").slice(1) + "\n"; // Remove leading comma

    // Get the unique categories from the apiResponse
    const secondCategories = Object.keys(apiResponse.cohort.total);

    let dataContent = "";
    // Iterate over each category
    for (const category of secondCategories) {
        // Add the category to the CSV content
        dataContent += `${formatLabels(category)},`;

        // Iterate over each subcategory
        for (const type of Object.keys(apiResponse)) {
            for (const description of categories) {
                if (description !== "total" && description !== "preemptive_retention") {
                    // Add the data for each description
                    let value = roundNumber(apiResponse[type][description][category], true) || 0;

                    if (category === "count") {
                        value = typeof value === "number" ? determineNdisplay(value, true) : value;
                    }
                    
                    if (category === "mean_percent_change" || category === "median_percent_change") {
                        value = typeof value === "number" ? convertDecimalToPercent(apiResponse[type][description][category]) : value;
                    }

                    dataContent += `${value},`;
                }
            }
        }

        // Move to the next line for the next category
        dataContent = dataContent.slice(0, -1) + "\n";
    }
    csvContent += dataContent;
    return csvContent;
}


const convertApiResponsesMeanSD = (
    apiResponse: NestedResponseData | null,
    selectedFilter: string
): string => {
    if (!apiResponse) return "";

    let csvContent = "";

    // Write the header row
    const types = Object.keys(apiResponse);

    let categories = Object.keys(apiResponse.cohort).filter((item) => item !== "overall");
    let columnComparisonList: any;
    columnComparisonList = modifiedCategories[selectedFilter as keyof Categories];

    categories = categories.sort((a: any, b: any) =>
        columnComparisonList.indexOf(a) > columnComparisonList.indexOf(b) ? 1 : -1
    );

    let firstHeader = "";

    for (const type of types) {
        firstHeader += `${formatLabels(type)},` + ",".repeat(categories.length - 1);
    }

    csvContent += `,${firstHeader}\n`;

    // Write the second row with category dynamically
    csvContent += categories.map(category => `,${removeCommas(getNameFromVariable(selectedFilter, category))}`).join("") + ",";
    csvContent += categories.map(category => `,${removeCommas(getNameFromVariable(selectedFilter, category))}`).join("").slice(1) + "\n"; // Remove leading comma

    const secondCategories = MEAN_STD_HEADERS;

    let dataContent = "";
    // Iterate over each category
    for (const category of secondCategories) {
        // Add the category to the CSV content
        dataContent += `${formatLabels(category)},`;

        // Iterate over each subcategory
        for (const type of Object.keys(apiResponse)) {
            for (const description of categories) {
                if (apiResponse[type][description]) {
                    for (const stat of Object.keys(apiResponse[type][description])) {
                        // account for description with no stats
                        if (stat === "count") {
                            if (apiResponse[type][description][stat] === 0) {
                                dataContent += `${INSUFFICIENT_N_DISPLAY},`;
                                continue;
                            }
                        }
                        if (stat === category) {
                            let value;
                            if (stat === "mean") {
                                value = roundNumber(apiResponse[type][description][category]) || 0;

                            } else if (stat === "stdev") {
                                value = roundNumber(apiResponse[type][description][category], true) || 0;
                            }
                            dataContent += `${value},`;
                        }
                    }
                } else {
                    dataContent += `${INSUFFICIENT_N_DISPLAY},`;
                }
            }
        }

        // Move to the next line for the next category
        dataContent = dataContent.slice(0, -1) + "\n";
    }

    csvContent += dataContent;

    return csvContent;
};

const convertApiResponsesMeanSDTripleNested = (
    apiResponse: TripleNestedResponseData | null,
    selectedFilter: string
): string => {
    if (!apiResponse) return "";

    let csvContent = "";

    // Write the header row
    const types = Object.keys(apiResponse.total);
    const workTitles = Object.keys(apiResponse).filter((item) => item !== "total");

    const categories = Object.keys(apiResponse.total.cohort).filter((item) => item !== "total");

    let rowComparisonList = modifiedCategories[selectedFilter as keyof Categories];

    categories.sort((a: any, b: any) =>
        rowComparisonList.indexOf(a) > rowComparisonList.indexOf(b) ? 1 : -1
    );


    let firstHeader = "";

    for (const type of types) {
        firstHeader += `${formatLabels(type)},` + ",".repeat((workTitles.length * categories.length) - 1);
    }

    csvContent += `,${firstHeader}\n`;

    // Write the second row with category dynamically
    let secondHeaderRow = ",";
    secondHeaderRow += createHeaderRowWithCommas(workTitles, categories.length);
    csvContent += secondHeaderRow.repeat(types.length) + "\n";

    // Write the third row with category dynamically
    let thirdHeaderRow = ",";
    thirdHeaderRow += createHeaderRowWithCommas(categories, 1);

    csvContent += thirdHeaderRow.repeat(types.length * workTitles.length) + "\n";

    const statistics = MEAN_STD_HEADERS;
    for (const stat of statistics) {
        csvContent += `${formatLabels(stat)},`;

        for (const type of types) {
            for (const title of workTitles) {
                let value;
                for (const category of categories) {
                    if (stat === "mean") {
                        value = roundNumber(apiResponse[title][type][category][stat]) || 0;
                    } else if (stat === "stdev") {
                        value = roundNumber(apiResponse[title][type][category][stat], true) || 0;
                    } else {
                        value = "n<5"
                    }
                    csvContent += `${value},`;
                }
            }
        }
        csvContent = csvContent.slice(0, -1) + "\n";
    }
    return csvContent;
}

const convertAverageRankFactorDataToCsvLeaveStay = (apiResponse: any, selectedFilter: string) => {
    if (!apiResponse) return "";

    let csvContent = "";


    // Write the header rows

    // row headers
    const factors = Object.keys(apiResponse);

    // first header
    const types = Object.keys(apiResponse[factors[0]]);

    // second header
    const secondHeader = REASON_TO_STAY_LEAVE_HEADERS;

    // third header
    let categories = Object.keys(apiResponse[factors[0]].cohort.leave).filter((item) => item !== "overall");
    const columnComparisonList = modifiedCategories[selectedFilter as keyof Categories];

    categories = categories.sort((a: any, b: any) =>
        columnComparisonList.indexOf(a) > columnComparisonList.indexOf(b) ? 1 : -1
    );

    // fourth headers    
    const dataHeaders = AVG_RANK_NUM_SELECTED_TOTAL_HEADERS;


    let firstHeader = "";
    for (const type of types) {
        firstHeader += `,${formatLabels(type)},` + ",".repeat((categories.length) * (secondHeader.length) * (dataHeaders.length) - 2);
    }


    csvContent += `${firstHeader}\n`;

    // add the reasons to leave/stay header
    let secondHeaderRow = ",";
    secondHeader.forEach((reason, index) => {
        secondHeaderRow += formatLabels(reason);
        if (index < secondHeader.length - 1) {
            secondHeaderRow += ",".repeat(categories.length * dataHeaders.length);
        }
    });
    secondHeaderRow += ",".repeat(categories.length * dataHeaders.length)

    csvContent += secondHeaderRow.slice(0, -1).repeat(2) + "\n";


    // add the third header row
    let thirdHeaderRow = "";
    thirdHeaderRow +=
        (categories
            .map(category =>
                `,${removeCommas(getNameFromVariable(selectedFilter, category))}${",".repeat((dataHeaders.length) - 1)}`)
            .join("")).repeat(types.length);

    csvContent += thirdHeaderRow.repeat(2) + ",\n";


    // add the fourth headers
    categories.forEach((category) => csvContent += `,${dataHeaders.join(",")}`.repeat(secondHeader.length * types.length))
    csvContent = csvContent + "\n";


    // add the data values
    for (const factor of factors) {
        csvContent += `${formatLabels(factor)},`;

        for (const type of types) {
            for (const reason of secondHeader) {
                for (const category of categories) {
                    if (apiResponse[factor][type][reason][category]) {
                        const averageRank = roundNumber(apiResponse[factor][type][reason][category].avg_rank);
                        const num_selected = roundNumber(apiResponse[factor][type][reason][category].num_selected);
                        const total = roundNumber(apiResponse[factor][type][reason][category].total);
                        csvContent += `${averageRank},${num_selected},${total},`;
                    } else {
                        csvContent += `${INSUFFICIENT_N_DISPLAY},${INSUFFICIENT_N_DISPLAY},${INSUFFICIENT_N_DISPLAY},`;
                    }
                }
            }
        }
        csvContent += "\n";
    }
    return csvContent;
}


const convertAverageRankFactorDataToCsv = (apiResponse: any, selectedFilter: string) => {
    if (!apiResponse) return "";

    let csvContent = "";


    // Write the header rows

    // row headers
    const factors = Object.keys(apiResponse);

    // first header
    const types = Object.keys(apiResponse[factors[0]]);

    // second header
    let categories = Object.keys(apiResponse[factors[0]].cohort).filter((item) => item !== "overall");
    const columnComparisonList = modifiedCategories[selectedFilter as keyof Categories];

    categories = categories.sort((a: any, b: any) =>
        columnComparisonList.indexOf(a) > columnComparisonList.indexOf(b) ? 1 : -1
    );

    // third headers    
    const dataHeaders = AVG_RANK_NUM_SELECTED_TOTAL_HEADERS;


    let firstHeader = "";
    for (const type of types) {
        firstHeader += `,${formatLabels(type)},` + ",".repeat((categories.length) * (dataHeaders.length) - 2);
    }


    csvContent += `${firstHeader}\n`;

    // add the second header row
    let secondHeaderRow = "";
    secondHeaderRow +=
        (categories
            .map(category =>
                `,${removeCommas(getNameFromVariable(selectedFilter, category))}${",".repeat((dataHeaders.length) - 1)}`)
            .join("")).repeat(types.length);

    csvContent += secondHeaderRow + ",\n";


    // add the third headers
    categories.forEach((category) => csvContent += `,${dataHeaders.join(",")}`.repeat(types.length))
    csvContent = csvContent + "\n";


    // add the data values
    for (const factor of factors) {
        csvContent += `${formatLabels(factor)},`;

        for (const type of types) {
            for (const category of categories) {
                if (apiResponse[factor][type][category]) {
                    const averageRank = roundNumber(apiResponse[factor][type][category].avg_rank);
                    const num_selected = roundNumber(apiResponse[factor][type][category].num_selected);
                    const total = roundNumber(apiResponse[factor][type][category].total);
                    csvContent += `${averageRank},${num_selected},${total},`;
                } else {
                    csvContent += `${INSUFFICIENT_N_DISPLAY},${INSUFFICIENT_N_DISPLAY},${INSUFFICIENT_N_DISPLAY},`;
                }
            }
        }
        csvContent += "\n";
    }
    return csvContent;
}

const convertTopFactorsDataToCsv = (apiResponse: any, selectedFilter: string) => {
    if (!apiResponse) return "";

    let factors: any[] = [];

    apiResponse.factors.forEach((factor: any) => {
        factors.push(Object.keys(factor)[0]);
    })

    let csvContent = "";


    // Write the header rows

    // first header
    let categoriesList = Object.keys(apiResponse.factors[0][factors[0]]);

    const columnComparisonList = modifiedCategories[selectedFilter as keyof Categories];

    categoriesList = categoriesList.sort((a: any, b: any) =>
        columnComparisonList.indexOf(a) > columnComparisonList.indexOf(b) ? 1 : -1
    );

    // second header
    const types = INSTITUTION_COHORT_HEADERS;

    // third headers    
    const dataHeaders = [
        "Rank: 1.0",
        "Rank: 2.0",
        "Rank: 3.0",
        "Rank: 4.0",
        "Rank: 5.0",
        "Number Selected",
        "Total"
    ];


    let firstHeaderRow = "";
    for (const category of categoriesList) {
        firstHeaderRow += `,${removeCommas(getNameFromVariable(selectedFilter, category))},` + ",".repeat((types.length) * (dataHeaders.length) - 2);
    }
    csvContent += `${firstHeaderRow}\n`;


    // add the types header
    let secondHeaderRow = ",";
    secondHeaderRow += types.map(type => formatLabels(type)).join(`${",".repeat(dataHeaders.length)}`) + ",".repeat(dataHeaders.length);


    csvContent += secondHeaderRow.slice(0, -1).repeat(categoriesList.length) + "\n";


    // add the data value headers
    categoriesList.forEach((category) => csvContent += `,${dataHeaders.join(",")}`.repeat(types.length))
    csvContent = csvContent + "\n";

    let factorsResponse = apiResponse.factors;

    // add the data values
    for (const factorObject of factorsResponse) {
        let factor = Object.keys(factorObject)[0];
        csvContent += `${formatLabels(factor)},`;

        for (const category of categoriesList) {
            for (const type of types) {
                if (factorObject[factor][category][type]) {
                    const rank1 = roundNumber(factorObject[factor][category][type]["1"]);
                    const rank2 = roundNumber(factorObject[factor][category][type]["2"]);
                    const rank3 = roundNumber(factorObject[factor][category][type]["3"]);
                    const rank4 = roundNumber(factorObject[factor][category][type]["4"]);
                    const rank5 = roundNumber(factorObject[factor][category][type]["5"]);
                    const num_selected = roundNumber(factorObject[factor][category][type].num_selected);
                    const total = roundNumber(factorObject[factor][category][type].total);

                    csvContent += `${rank1},${rank2},${rank3},${rank4},${rank5},${num_selected},${total},`;
                } else {
                    csvContent += `${INSUFFICIENT_N_DISPLAY},${INSUFFICIENT_N_DISPLAY},${INSUFFICIENT_N_DISPLAY},${INSUFFICIENT_N_DISPLAY},${INSUFFICIENT_N_DISPLAY},${INSUFFICIENT_N_DISPLAY},`;
                }
            }
        }
        csvContent += "\n";
    }
    return csvContent;
}

interface ApiResponseToCSVConverterProps {
    apiResponse: NestedResponseData | TripleNestedResponseData | FactorsResponse | any | null;
    selectedFilter: string;
    filename: string;
    totalRowOnly?: boolean;
    section?: string
    apiResponseB?: NestedResponseData | TripleNestedResponseData | null;
    factorOrder?: any[]
}


const ApiResponseToCSVConverter: React.FC<ApiResponseToCSVConverterProps> = ({ apiResponse,
    selectedFilter,
    filename,
    totalRowOnly = false,
    section,
    apiResponseB,
}) => {

    const handleDownloadClick = () => {
        let csvContent;
        if (section === "5.2a" || section === "5.2b" || section === "6.7" || section === "8.2") {
            let csvContentA = convertApiResponseToCSVPopulationData(apiResponse as NestedResponseData, selectedFilter, false, section, true);
            let csvContentB = convertApiResponsesMeanSD(apiResponseB as NestedResponseData, selectedFilter);

            csvContent = `${csvContentA}\n\n\n${csvContentB}`
        } else if (section === "2.1") {
            csvContent = convertAverageRankFactorDataToCsvLeaveStay(apiResponse, selectedFilter);
        } else if (section === "2.2") {
            csvContent = convertTopFactorsDataToCsv(apiResponse, selectedFilter);
        } else if (section === "6.3") {
            csvContent = convertApiResponseToCSVStatistics(apiResponse as NestedResponseData, selectedFilter);
        } else if (section === "6.4") {
            csvContent = convertAverageRankFactorDataToCsv(apiResponse, selectedFilter);
        } else if (section === "6.6") {
            csvContent = convertApiResponseToCSVPopulationDataTripledNested(apiResponse as TripleNestedResponseData, selectedFilter, section);
        } else if (section === "7.1") {
            let csvContentA = convertApiResponseToCSVPopulationDataTripledNestedSection7(apiResponse as TripleNestedResponseData, selectedFilter, section);
            let csvContentB = convertApiResponsesMeanSDTripleNested(apiResponseB as TripleNestedResponseData, selectedFilter);
            csvContent = `${csvContentA}\n\n\n${csvContentB}`
        } else {
            let answerChoice = false;

            const sectionsWithAnswerChoiceAsRowHeader = [
                "3.1", "3.2", "3.3", "3.4",
                "4.1a", "4.1b", "4.2", "4.3a", "4.3b", "4.4",
                "5.1",
                "6.5",
                "8.1", "8.3", "8.4",
                "9.1"
            ]
            if (section && sectionsWithAnswerChoiceAsRowHeader.includes(section)) {
                answerChoice = true;
            }
            csvContent = convertApiResponseToCSVPopulationData(apiResponse as NestedResponseData, selectedFilter, totalRowOnly, section, answerChoice);
        }

        // Create a download link
        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${filename}.csv`;

        // Append the link to the document body
        document.body.appendChild(link);

        // Initiate the download
        link.click();

        // Revoke the object URL immediately after the download is initiated
        URL.revokeObjectURL(link.href);
    };

    return (
        <div className="csv-button-container">
            <button className="csv-button" onClick={handleDownloadClick} >
                Download CSV
            </button>
        </div>
    );
};

export default ApiResponseToCSVConverter;

