import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicableYear } from "../models/responseData";

interface ExitReportCardProps {
    applicableYear: ApplicableYear;
    institution: any;
}

const ExitReportCard: FC<ExitReportCardProps> = ({ applicableYear, institution }) => {
    const navigate = useNavigate();
    const routeChange = () => {
        navigate(`ExitRetentionReport/${institution.institution_name}/${applicableYear.report_type}/${applicableYear.year}`);
    }

    return (
        <>
            <div className="report-card">
                <h3>{applicableYear.year}</h3>
                {/* Place holder for executive summary */}
                {/* <div>
                    <span className="report-card-text-inactive">Executive Summary</span><span> &gt;</span>
                </div> */}
                {applicableYear.report_type === "final" && (
                    <div onClick={routeChange}>
                        <span className="report-card-text">Final Report</span><span> &gt;</span>
                    </div>
                )}

                {applicableYear.report_type === "interim" && (
                    <div onClick={routeChange}>
                        <span className="report-card-text">Interim Report</span><span> &gt;</span>
                    </div>
                )}
            </div>
        </>

    )
}

export default ExitReportCard;