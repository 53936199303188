import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { FactorsResponse } from "../../models/responseData";
import {
  transformDataFactors,
  TransformedDataFactors
} from "../../utils/transformDataUtils";
import OverlappingHistogram from "../../charts/histogramChart/overlappingHistogram";
import InstitutionN from "../../components/InstitutionN";
import PageLoader from "../../components/PageLoader";
import ApiResponseToCSVConverter from "../../utils/csvDownload/ApiResponseToCsvConverter";
import { determineResponseTypeFactorsResponse } from "../../utils/determineResponseType";


const PrimaryAndSecondaryFactors = () => {
  const [categoryFilter, setCategoryFilter] = useState("exit_status");
  const [reason, setReason] = useState("stay");
  const [apiResponse, setApiResponse] = useState<FactorsResponse | null>(null);
  const [institutionN, setInstitutionN] = useState(0);
  const [loading, setLoading] = useState(true);
  const [factorOrder, setFactorOrder] = useState<string[]>();

  const [transformedResponse, setTransformedResponse] = useState<TransformedDataFactors[] | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();


  useEffect(() => {
    const getFactorData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section2/subsection2/?report_year=${year}&reason=${reason}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeFactorsResponse(data)) {
        setFactorOrder(data.factors.map((factor) => Object.keys(factor)[0]));
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getFactorData();
  }, [getAccessTokenSilently, reason, year]);


  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section2/subsection2/?report_year=${year}&reason=${reason}&group_by=${categoryFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeFactorsResponse(data) && factorOrder) {
        setApiResponse(data);
        setTransformedResponse(transformDataFactors(data, categoryFilter, factorOrder));
        setInstitutionN(data.total.institution);
        setLoading(false);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [getAccessTokenSilently, categoryFilter, reason, factorOrder, year]);

  return (
    <>
      <h2>2.2 Top factors in the decision to stay or leave</h2>
      <div>
        <div>Tips for reading the graph:</div>
        <p>
          The top five most frequently selected reasons to leave and reasons to stay are displayed respectively below. In the bar graph, the X-axis shows the exact ranking of importance of the factor, from the left indicating least important and the right indicating most important. The Y-axis indicates the percentage of respondents who ranked each factor from least important to most important. For example, the bar furthest to the right indicates the percentage of respondents who ranked this factor as most important.
        </p>
      </div>

      <div className="page-layout">
        {loading ? <PageLoader /> :
          <div>
            <InstitutionN institutionN={institutionN} />
            <div>
              <select onChange={(e) => setCategoryFilter(e.target.value)}>
                <option value="exit_status">Overall</option>
                <option value="gender">Gender</option>
                <option value="race_ethnicity">Race/Ethnicity</option>
                <option value="tenure_status">Tenured Status</option>
                <option value="discipline">Discipline</option>
              </select>
              <select onChange={(e) => setReason(e.target.value)}>
                <option value="stay">Reasons to Stay</option>
                <option value="leave">Reasons to Leave</option>
              </select>
            </div>
          </div>
        }
        {
          transformedResponse && factorOrder &&
          <>
            <div>
              <OverlappingHistogram
                data={transformedResponse}
                selectedFilter={categoryFilter}
                institutionN={institutionN}
                factorOrder={factorOrder}
                reason={reason}
              />
            </div>
            <ApiResponseToCSVConverter
              apiResponse={apiResponse}
              selectedFilter={categoryFilter}
              filename="2.2_Top_factors"
              section="2.2"
            />
          </>
        }

      </div>
      
    </>
  )
}

export default PrimaryAndSecondaryFactors;